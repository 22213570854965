import { Divider, Stack } from '@mui/material';
import { DialogPaper } from '@nexdynamic/nex-ui-react';
import { wait } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../ApplicationState';
import { LoaderEvent } from '../../../Events/LoaderEvent';
import { GlobalFlags } from '../../../GlobalFlags';
import { Api } from '../../../Server/Api';
import { openSystemBrowser } from '../../../Utilities';
import EmailProviderActions from '../components/EmailProviderActions';
import EmailEngineQuotaReport from '../components/QuotaReport';
import { useEmailEngine } from '../provider/EmailEngineProvider';
import { useEmailSettingsViewRouter } from '../useEmailSettingsViewRouter';

export const GmailSettings = () => {
    const { navigateTo } = useEmailSettingsViewRouter();

    const {
        account,
        reconnectAccount,
        reconnecting,
        disconnectAccount,
        disconnecting,
        syncAccount,
        syncing,
        registerAccount,
        emailProviders: { gmail },
    } = useEmailEngine();

    if (!gmail) return null;

    const handleDisconnectProvider = () => {
        navigateTo('email-settings');
        disconnectAccount();
    };

    const handleConnectClick = async () => {
        try {
            if (!gmail) return;

            new LoaderEvent(true, true, 'email-settings.initialising-connection-to-google');

            if (gmail.connected) return await reconnectAccount();

            const host = GlobalFlags.isMobileApp ? 'web+squeegee:/' : Api.currentHostAndScheme;
            const path = '/email-settings';

            const squeegeeUrl = host + path;

            const redirectUrl = `${Api.currentHostAndScheme}/api/email-engine/connected-redirect?uuid=${
                ApplicationState.account.uuid
            }&squeegeeUrl=${encodeURIComponent(squeegeeUrl)}`;

            const authLink = await registerAccount({ type: gmail.type, redirectUrl });
            if (!authLink) return;

            if (GlobalFlags.isMobileApp) openSystemBrowser(authLink);
            else window.open(authLink, '_parent');
        } finally {
            await wait(1000);
            new LoaderEvent(false);
        }
    };

    return (
        <>
            <Stack gap={1.5} direction="row">
                <DialogPaper sx={{ p: 2, width: '100%' }}>
                    <Stack gap={1.5}>
                        <EmailProviderActions
                            provider={gmail}
                            handleConnect={handleConnectClick}
                            handleDisconnect={handleDisconnectProvider}
                            handleSync={syncAccount}
                            loading={disconnecting || reconnecting || syncing}
                        />
                        {account?.quota && gmail.connected && <EmailEngineQuotaReport providerName={gmail.name} quota={account.quota} />}
                    </Stack>
                    <Divider sx={{ my: 1 }} />
                </DialogPaper>
            </Stack>
        </>
    );
};
