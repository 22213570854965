import type { Quote } from '@nexdynamic/squeegee-common';
import { Currency } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { QuoteActions } from '../QuoteActions';

export class QuoteCustomElement {
    @bindable quote?: Quote;

    @computedFrom('quote')
    protected get addressDescription() {
        if (!this.quote) return;

        if (this.quote.items.length === 1 && this.quote.items[0].address) {
            return this.quote.items[0].address.addressDescription || '';
        } else {
            return this.quote.address.addressDescription || '';
        }
    }

    protected view = () => {
        if (!this.quote) return;
        QuoteActions.view(this.quote);
    };

    @computedFrom('quote.currency')
    get currencySymbol() {
        if (!this.quote) return;
        return Currency.get(this.quote.currency)?.symbol;
    }
}
