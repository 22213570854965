import { Box, Input, Slider, Typography } from '@mui/material';
import { useState } from 'react';
import { t } from '../../../../t';

type Props = {
    min: number;
    max: number;
    onChange: (value: [number, number]) => void;
};

export const NumberRangePicker = ({ min, max, onChange }: Props) => {
    const maximumAllowedRange = 1095;
    const minimumAllowedRange = 0;
    const [range, setRange] = useState<[number, number]>([min, max]);

    const handleChange = (_event: Event, newValue: number | Array<number>) => {
        if (Array.isArray(newValue)) {
            setRange([newValue[0], newValue[1]]);
            onChange?.([newValue[0], newValue[1]]);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const value = event.target.value === '' ? 0 : Number(event.target.value);

        if (value > maximumAllowedRange || value < minimumAllowedRange) return;

        setRange((prev: [number, number]) => {
            const newRange = [...prev];
            newRange[index] = value;
            return [newRange[0], newRange[1]];
        });
    };

    return (
        <Box display="flex" flexDirection="column" gap={2} width="100%">
            <Typography variant="subtitle1">{t('range.select-range-title')}</Typography>
            <Typography variant="body2">{t('range.select-range-title-description')}</Typography>
            <Box display="flex" alignItems="center" gap={2}>
                <Input
                    type="number"
                    value={Number(range[0])}
                    onChange={e => handleInputChange(e, 0)}
                    sx={{
                        flex: 0.2,
                    }}
                    inputProps={{
                        min: minimumAllowedRange,
                        max: maximumAllowedRange,
                    }}
                />
                <Slider
                    value={range}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    min={minimumAllowedRange}
                    max={maximumAllowedRange}
                    sx={{
                        flex: 0.6,
                    }}
                />
                <Input
                    type="number"
                    value={Number(range[1])}
                    onChange={e => handleInputChange(e, 1)}
                    sx={{
                        flex: 0.2,
                    }}
                    inputProps={{
                        min: minimumAllowedRange,
                        max: maximumAllowedRange,
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Typography>
                    {t('range.min-days')}: {range[0]}
                </Typography>
                <Typography>
                    {t('range.max-days')}: {range[1]}
                </Typography>
            </Box>
        </Box>
    );
};
