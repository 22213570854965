/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Container, MobileStepper, Paper, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { GradientButton, useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import type { IDirectoryEntryDetails } from '@nexdynamic/squeegee-portal-common';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationState } from '../../../../ApplicationState';
import { LoaderEvent } from '../../../../Events/LoaderEvent';
import useTranslation from '../../../hooks/useTranslation';
import { useDirectoryEntryProvider } from '../../useDirectoryEntryDetails';
import { GettingStartedStep } from '../components/wizard/GettingStarted';
import { ListingInformationStep } from '../components/wizard/ListingInformation';
import PreviewAndPublishStep from '../components/wizard/PreviewAndPublish';
import type { GettingStartedOptions } from '../types/GettingStarted';
import type { ListingInformationOptions } from '../types/ListingInformationSettings';

export const SetupWizard = () => {
    const { directory, saveDirectory } = useDirectoryEntryProvider();
    const [directoryEntry, setDirectoryEntry] = useState<IDirectoryEntryDetails | undefined>();
    const originalDirectory = useRef(directory);

    useEffect(() => {
        if (originalDirectory.current !== undefined) return;
        console.log('Setting directory entry');
        setDirectoryEntry(directory);
        originalDirectory.current = directory;
    }, [directory]);

    const handleSave = async () => {
        const newDirectoryEntry = {
            ...directoryEntry,
            id: ApplicationState.account.uuid,
            setupComplete: true,
            businessName: listingInformation.businessName,
            summary: listingInformation.businessDescription,
            uniqueUrl: gettingStarted.urlHandle,
            addressDescription: listingInformation.businessAddress,
            email: listingInformation.emailAddress,
            telephone: listingInformation.contactNumber,
            logo: listingInformation.logo,
            banner: listingInformation.coverImage,
        } as IDirectoryEntryDetails;

        new LoaderEvent(true);
        await saveDirectory(newDirectoryEntry);
        new LoaderEvent(false);
    };

    const [activeStep, setActiveStep] = useState(0);

    const [gettingStarted, setGettingStarted] = useState<GettingStartedOptions>({
        termsAccepted: false,
        urlHandle: '',
    });

    const [listingInformation, setListingInformation] = useState<ListingInformationOptions>({
        logo: '',
        coverImage: '',
        businessName: '',
        businessDescription: '',
        contactNumber: '',
        showContactNumber: false,
        emailAddress: '',
        showEmailAddress: false,
        businessAddress: '',
        showBusinessAddress: false,
    });

    // const [theme, setTheme] = useState<PortalTheme>(themePresets[0]);

    const handleListingInformationChange = (key: string, value: string | boolean) => {
        setListingInformation({ ...listingInformation, [key]: value });
    };

    const nextDisabled = () => {
        switch (activeStep) {
            case 0:
                return !gettingStarted.termsAccepted || !gettingStarted.urlHandle;
            case 1:
                return !listingInformation.businessName || !listingInformation.businessDescription;
            default:
                return false;
        }
    };

    const isSmallScreen = useIsSmallScreen();
    const nav = useNavigate();
    const { t } = useTranslation();

    return (
        <Container
            maxWidth="md"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: { xs: 'unset', sm: '100%' },
                py: 2,
            }}
        >
            <Paper sx={{ p: 2, width: '100%' }}>
                <Stepper activeStep={activeStep} sx={{ mt: 1, mb: 4 }} alternativeLabel={isSmallScreen}>
                    <Step>
                        <StepLabel>Getting Started</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Listing Information</StepLabel>
                    </Step>
                    {/* <Step>
                        <StepLabel>Choose a Theme</StepLabel>
                    </Step> */}
                    <Step>
                        <StepLabel>Preview & Publish</StepLabel>
                    </Step>
                </Stepper>

                {activeStep === 0 && <GettingStartedStep gettingStarted={gettingStarted} setGettingStarted={setGettingStarted} />}

                {activeStep === 1 && (
                    <ListingInformationStep
                        listingInformation={listingInformation}
                        handleListingInformationChange={handleListingInformationChange}
                    />
                )}

                {/* {activeStep === 2 && <ChooseThemeStep />} */}
                {activeStep === 2 && (
                    <PreviewAndPublishStep gettingStartedOptions={gettingStarted} listingInformationOptions={listingInformation} />
                )}

                {!isSmallScreen ? (
                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <Button variant="outlined" onClick={() => setActiveStep(activeStep - 1)} disabled={activeStep === 0}>
                            {t('global.back')}
                        </Button>
                        <GradientButton
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (activeStep === 2) {
                                    handleSave();
                                    nav('/portal-directory');
                                } else setActiveStep(activeStep + 1);
                            }}
                            disabled={nextDisabled()}
                        >
                            {activeStep === 2 ? t('general.publish') : t('general.next')}
                        </GradientButton>
                    </Stack>
                ) : (
                    <MobileStepper
                        variant="progress"
                        steps={4}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                onClick={async () => {
                                    if (activeStep === 2) {
                                        await handleSave();
                                        nav('/portal-directory');
                                    } else setActiveStep(activeStep + 1);
                                }}
                                disabled={nextDisabled()}
                            >
                                {activeStep === 2 ? t('general.publish') : t('general.next')}
                            </Button>
                        }
                        backButton={
                            <Button onClick={() => setActiveStep(activeStep - 1)} disabled={activeStep === 0}>
                                {t('global.back')}
                            </Button>
                        }
                        sx={{ bgcolor: 'transparent' }}
                    />
                )}
            </Paper>
        </Container>
    );
};
