import type { Customer, Transaction } from '@nexdynamic/squeegee-common'
import moment from 'moment'
import { ApplicationState } from '../../ApplicationState'

export interface CustomerRiskProfile {
    riskScore: number
    riskLevel: 'Low' | 'Medium' | 'High' | 'Critical'
    contributingFactors: string[]
}

export function calculateCustomerRisk(customer: Customer, transactions: Array<Transaction>): CustomerRiskProfile {
    const t = ApplicationState.localise

    const now = moment()
    let totalPendingAmount = 0
    let oldestPendingDays = 0
    let pendingTransactionsCount = 0
    let hasPendingMandate = false
    const contributingFactors: string[] = []

    hasPendingMandate = customer.paymentProviderMetaData?.gocardless?.status === 'pending'

    transactions.forEach(transaction => {
        const failedAt = transaction.paymentDetails?.paymentHistory?.find(event => event.action === 'failed')?.created

        if (transaction.status === 'pending') {
            pendingTransactionsCount++
            totalPendingAmount += transaction.amount

            const transactionDate = moment(failedAt ?? transaction.date)
            const daysPending = now.diff(transactionDate, 'days')
            if (oldestPendingDays === 0 || daysPending > oldestPendingDays) {
                oldestPendingDays = daysPending
            }
        }

        if (transaction.externalIds && (transaction.externalIds['gocardless'] || transaction.externalIds['stripe'])) {
            const pendingStatus = customer.paymentProviderMetaData?.gocardless?.status === 'pending'
            hasPendingMandate = hasPendingMandate || pendingStatus
        }
    })

    let riskScore = calculateRiskScore(
        pendingTransactionsCount,
        oldestPendingDays,
        totalPendingAmount,
        hasPendingMandate,
        customer.paymentProviderMetaData?.gocardless ? 'gocardless' : 'stripe',
    )

    if (customer.paymentProviderMetaData?.gocardless?.secondSignatureRequired) {
        contributingFactors.push(t('pending.risk-factor-second-signature'))
        riskScore = 10000
    }

    const riskLevel = determineRiskLevel(riskScore)

    if (pendingTransactionsCount > 0) {
        contributingFactors.push(t('pending.risk-factor-transactions', { total: pendingTransactionsCount.toString() }))
    }
    if (oldestPendingDays > 11) {
        contributingFactors.push(t('pending.risk-factor-oldest-transaction', { days: oldestPendingDays.toString() }))
    }
    if (totalPendingAmount > 100) {
        contributingFactors.push(t('pending.risk-factor-high-amount', { amount: totalPendingAmount.toString() }))
    }
    if (hasPendingMandate) {
        contributingFactors.push(t('pending.risk-factor-pending-mandate'))
    }

    return { riskScore, riskLevel, contributingFactors }
}

function calculateRiskScore(
    pendingCount: number,
    oldestPendingDays: number,
    totalPendingAmount: number,
    hasPendingMandate: boolean,
    provider: 'gocardless' | 'stripe',
): number {
    let score = 0

    if (provider === 'gocardless') {
        score += oldestPendingDays > 11 ? oldestPendingDays * 10 : oldestPendingDays * 5
    } else if (provider === 'stripe') {
        // if a pending card payment is older than 1 day, we're seriously concerned
        score += oldestPendingDays > 1 ? oldestPendingDays * 100 : oldestPendingDays * 5
    }

    score += pendingCount * 30
    score += totalPendingAmount / 10
    if (hasPendingMandate) {
        score += 25
    }
    return score
}

function determineRiskLevel(score: number): 'Low' | 'Medium' | 'High' | 'Critical' {
    if (score < 50) {
        return 'Low'
    } else if (score < 100) {
        return 'Medium'
    } else if (score < 200) {
        return 'High'
    } else {
        return 'Critical'
    }
}
