import moment from 'moment';
import { t } from '../../../t';
import type { QuickScheduleOptions } from '../QuickScheduleOptions';

export const generateDays = (targetDate: Date, dayOptions: QuickScheduleOptions['availableDayOptions']) => {
    return (
        dayOptions?.map(({ label, value }) => {
            const date = moment(targetDate).startOf('day').add(value, 'days');
            return {
                label: label ? t(label) : date.format('ddd, Do MMM'),
                date: date.format('YYYY-MM-DD'),
                value,
                // if one day before is the same as today, disable one day before. Or if it's an email, disable if more than 48 hours in the future.
                // disabled: date.isBefore(moment().startOf('day')) || method === 'email' ? date.isAfter(moment().add(72, 'hours')) : false,
                disabled: date.isBefore(moment().startOf('day')),
            };
        }) ?? []
    );
};
