import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterListIcon from '@mui/icons-material/FilterList';
import InfoIcon from '@mui/icons-material/Info';
import CriticalIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Alert,
    Avatar,
    Box,
    Chip,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper,
    Popover,
    Stack,
    Tab,
    Tabs,
    Typography,
    styled,
} from '@mui/material';
import { DialogRoot } from '@nexdynamic/nex-ui-react';
import type { Transaction } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { ConnectedServicesService } from '../../../ConnectedServices/ConnectedServicesService';
import type { IConnectedServiceInfo } from '../../../ConnectedServices/IConnectedServiceInfo';
import { checkCustomerPaymentStatuses } from '../../../Customers/methods/checkCustomerPaymentStatuses';
import { Data } from '../../../Data/Data';
import { AureliaReactComponentDialog } from '../../../Dialogs/AureliaReactComponentDialog';
import { LoaderEvent } from '../../../Events/LoaderEvent';
import { Logger } from '../../../Logger';
import { Utilities } from '../../../Utilities';
import LoadingScreen from '../../components/LoadingScreen';
import useTranslation from '../../hooks/useTranslation';
import type { PendingViewSettings } from '../components/Filters';
import Filters from '../components/Filters';
import type { PendingCustomerDialogProps } from '../components/PendingCustomerDialog';
import PendingCustomerDialog from '../components/PendingCustomerDialog';
import Price from '../components/Price';
import type { PendingCustomer } from '../hooks/usePendingCustomers';
import { usePendingCustomers } from '../hooks/usePendingCustomers';

export const XsChip = styled(Chip)(() => ({
    'height': 20,
    'borderRadius': 4,
    '& .MuiChip-label': {
        padding: 4,
    },
    '& .MuiChip-icon': {
        marginRight: 0,
        fontSize: '1rem',
    },
}));

const PendingMain = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [goCardlessConnection, setGoCardlessConnection] = useState<IConnectedServiceInfo['status']>('not-connected');
    const [stripeConnection, setStripeConnection] = useState<IConnectedServiceInfo['status']>('not-connected');
    const [showGocardless, setShowGocardless] = useState<boolean>(false);
    const [showStripe, setShowStripe] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        ConnectedServicesService.getProviders().then(providers => {
            let showGc = false;
            let showS = false;
            for (const provider of providers) {
                switch (provider.id) {
                    case 'gocardless': {
                        setGoCardlessConnection(provider.status);
                        setShowGocardless(true);
                        const hasPendingTransactions = Data.exists<Transaction>(
                            'transactions',
                            transaction =>
                                transaction.paymentDetails?.paymentProvider === 'gocardless' &&
                                transaction.status === 'pending' &&
                                !!Data.get(transaction.customerId || '')
                        );
                        showGc = hasPendingTransactions || provider.status === 'connected';
                        setShowGocardless(showGc);
                        break;
                    }
                    case 'stripe': {
                        setStripeConnection(provider.status);
                        setShowStripe(true);
                        const hasPendingTransactions = Data.exists<Transaction>(
                            'transactions',
                            transaction =>
                                transaction.paymentDetails?.paymentProvider === 'stripe' &&
                                transaction.status === 'pending' &&
                                !!Data.get(transaction.customerId || '')
                        );
                        showS = hasPendingTransactions || provider.status === 'connected';
                        setShowStripe(showS);
                        break;
                    }
                }
            }

            if (showGc) setSelectedTab('gocardless');
            else if (showS) setSelectedTab('stripe');

            setLoading(false);
        });
    }, []);

    const [selectedTab, setSelectedTab] = useState<'gocardless' | 'stripe'>();
    const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
    const [settings, setSettings] = useState<PendingViewSettings>(JSON.parse(localStorage.getItem('pendingCustomersSettings') || '{}'));

    const handleSettingChange = (setting: string, value: boolean) => {
        setSettings({ ...settings, [setting]: value });
        localStorage.setItem('pendingCustomersSettings', JSON.stringify({ ...settings, [setting]: value }));
    };

    const pendingCustomers = usePendingCustomers(selectedTab);
    const totalPendingAmount = pendingCustomers.reduce((total, customer) => total + customer.balance.pending, 0);

    const onCustomerSelect = async (customer: PendingCustomer) => {
        if (!selectedTab) return;

        new LoaderEvent(true, false);
        await checkCustomerPaymentStatuses(customer._id).catch(error => Logger.error('Error checking customer payment statuses', error));
        new LoaderEvent(false);

        AureliaReactComponentDialog.show<undefined, PendingCustomerDialogProps>({
            component: PendingCustomerDialog,
            componentProps: {
                customer: customer,
                paymentProvider: selectedTab,
            },
            dialogTitle: 'pending.customer-details-dialog',
            isSecondaryView: true,
        });
    };

    if (!loading && !showGocardless && !showStripe) {
        return (
            <Alert severity="info" variant="filled" sx={{ borderRadius: 0 }}>
                {t('pending.no-connected-providers')}
            </Alert>
        );
    }

    return (
        <>
            <LoadingScreen open={loading} />
            <DialogRoot sx={{ p: 0 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', m: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
                        {showGocardless && <Tab label="GoCardless" value="gocardless" />}
                        {showStripe && <Tab label="Stripe" value="stripe" />}
                    </Tabs>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={event => setFilterAnchorEl(event.currentTarget)}>
                            <FilterListIcon />
                        </IconButton>
                    </Box>
                    <Popover
                        open={!!filterAnchorEl}
                        anchorEl={filterAnchorEl}
                        onClose={() => setFilterAnchorEl(null)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Filters onSettingChange={handleSettingChange} settings={settings} />
                    </Popover>
                </Box>
                {selectedTab === 'gocardless' && showGocardless && goCardlessConnection !== 'connected' && (
                    <Alert severity="warning" variant="filled" sx={{ borderRadius: 0 }}>
                        {t('pending.missing-connections', { provider: 'GoCardless' })}
                    </Alert>
                )}
                {selectedTab === 'stripe' && showStripe && stripeConnection !== 'connected' && (
                    <Alert severity="warning" variant="filled" sx={{ borderRadius: 0 }}>
                        {t('pending.missing-connections', { provider: 'Stripe' })}
                    </Alert>
                )}
                <Stack direction="row" justifyContent="space-between" alignItems="center" px={2}>
                    <Typography variant="caption">
                        {t('pending.total-pending-customers', {
                            total: pendingCustomers.length.toString(),
                            paymentProvider: selectedTab === 'gocardless' ? 'GoCardless' : 'Stripe',
                        })}
                    </Typography>
                    <Price price={totalPendingAmount} />
                </Stack>
                {pendingCustomers.length !== 0 && (
                    <List sx={{ width: '100%', bgcolor: 'background.paper', mb: 8 }} disablePadding>
                        {pendingCustomers
                            .filter(customer => settings.showLowRiskCustomers || customer.risk.riskLevel !== 'Low')
                            .sort((a, b) => b.risk.riskScore - a.risk.riskScore)
                            .map((customer, index) => {
                                return (
                                    <ListItem disablePadding key={customer._id} divider={index < pendingCustomers.length - 1}>
                                        <ListItemButton onClick={() => onCustomerSelect(customer)}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={`${customer.name}'s Avatar`}
                                                    sx={{ bgcolor: Utilities.textToColour(customer.name) }}
                                                >
                                                    {Utilities.getAvatarTextFromName(customer.name)}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Typography sx={{ flexGrow: 1 }}>{customer.name}</Typography>}
                                                secondary={
                                                    <>
                                                        {customer.address.addressDescription}
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexGrow: 1,
                                                                justifyContent: 'space-between',
                                                                alignItems: 'flex-end',
                                                            }}
                                                        >
                                                            <Stack direction="row" spacing={0.5} overflow="hidden" pt={0.2}>
                                                                <XsChip
                                                                    label={selectedTab === 'gocardless' ? 'GoCardless' : 'Stripe'}
                                                                    color="primary"
                                                                    size="small"
                                                                />
                                                                <XsChip
                                                                    label={
                                                                        t('general.pending').charAt(0).toUpperCase() +
                                                                        t('general.pending').slice(1)
                                                                    }
                                                                    color="warning"
                                                                    size="small"
                                                                />

                                                                <XsChip
                                                                    label={customer._externalId}
                                                                    size="small"
                                                                    sx={{
                                                                        color: 'text.secondary',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}
                                                                />
                                                            </Stack>
                                                        </Box>
                                                    </>
                                                }
                                            />
                                            <Stack ml={2} alignItems="flex-end">
                                                <Typography variant="caption" color="text.secondary">
                                                    {t('pending.amount-pending', {
                                                        pendingCount: customer.pendingTransactionsCount.toString(),
                                                    })}
                                                </Typography>
                                                <Price price={customer.balance.pending} />
                                                <XsChip
                                                    label={`${customer.risk.riskLevel} Risk`}
                                                    color={
                                                        customer.risk.riskLevel === 'High' || customer.risk.riskLevel === 'Critical'
                                                            ? 'error'
                                                            : customer.risk.riskLevel === 'Medium'
                                                            ? 'warning'
                                                            : 'info'
                                                    }
                                                    size="small"
                                                    icon={
                                                        customer.risk.riskLevel === 'Critical' ? (
                                                            <CriticalIcon />
                                                        ) : customer.risk.riskLevel === 'High' || customer.risk.riskLevel === 'Medium' ? (
                                                            <WarningIcon />
                                                        ) : (
                                                            <InfoIcon />
                                                        )
                                                    }
                                                    sx={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                />
                                            </Stack>
                                            <ChevronRightIcon sx={{ color: 'action.active', ml: 2 }} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                    </List>
                )}
                {pendingCustomers.length === 0 && (
                    <Paper sx={{ mx: 2 }}>
                        <Typography sx={{ p: 4 }} textAlign="center">
                            {t('pending.no-pending-customers-for-payment-provider', {
                                paymentProvider: selectedTab === 'gocardless' ? 'GoCardless' : 'Stripe',
                            })}
                        </Typography>
                    </Paper>
                )}
            </DialogRoot>
        </>
    );
};

export default PendingMain;
