import type { Customer, Notification } from '@nexdynamic/squeegee-common';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerDialog, CustomerDialogTab } from '../../../Customers/Components/CustomerDialog';
import { Data } from '../../../Data/Data';
import { Logger } from '../../../Logger';
import { NotificationService } from '../../../Notifications/NotificationService';
import { fetchNotification } from '../../../Notifications/functions/fetchNotification';
import { Api } from '../../../Server/Api';
import { openSystemBrowser } from '../../../Utilities';
import useTranslation from '../../hooks/useTranslation';

const MessageView = () => {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        let dialog: CustomerDialog;
        const view = async (): Promise<void> => {
            if (id) {
                const notification = Data.get<Notification>(id) || (await fetchNotification({ id }));

                if (!notification) {
                    enqueueSnackbar(t('customers.unable-to-view-deleted'), { variant: 'error' });
                    return Logger.error('Notification not found', { id });
                }

                const customer = Data.get<Customer>(notification.customerId || notification.addressee);
                if (customer) {
                    dialog = new CustomerDialog(customer, CustomerDialogTab.MESSAGES);
                    await dialog.show();
                } else {
                    if (notification.unread === true) {
                        NotificationService.markAsRead([notification._id]).catch(() => {
                            // do nothing
                        });
                    }
                    openSystemBrowser(Api.currentHostAndScheme + '/api/notifications/view/' + notification._id);
                }
            }
        };
        view();

        return () => {
            dialog?.cancel();
        };
    }, [id, enqueueSnackbar, t, navigate]);

    return null;
};

export default MessageView;
