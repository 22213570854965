import { type Notification } from '@nexdynamic/squeegee-common';
import { Api } from '../../Server/Api';

type FetchArchivedCustomerConversationParams = {
    take: number;
    skip: number;
    customerId?: string;
};

type FetchArchivedCustomerConversationResult =
    | {
          success: false;
          error: string;
      }
    | {
          success: true;
          notifications: Array<Notification>;
      };

export const fetchArchivedCustomerConversation = async (params: FetchArchivedCustomerConversationParams) => {
    try {
        // take the params and add them to the url as query params
        let url = `/api/notifications/conversation?take=${params.take}&skip=${params.skip}`;
        if (params.customerId) url += `&customerId=${params.customerId}`;

        const result = await Api.get<FetchArchivedCustomerConversationResult>(null, url);

        if (result?.data.success === true) {
            return result.data.notifications;
        } else {
            throw new Error(result?.data.error);
        }
    } catch (error) {
        throw new Error(error);
    }
};
