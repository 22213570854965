import type { Customer, Transaction } from '@nexdynamic/squeegee-common';
import { Data } from '../../../Data/Data';

export const usePendingTransactions = (customer: Customer) => {
    const transactions = Data.all<Transaction>('transactions').filter(
        transaction => transaction.customerId === customer._id && !transaction.voidedId && transaction.status === 'pending'
    );

    return transactions;
};
