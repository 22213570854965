import type { Quote } from '@nexdynamic/squeegee-common';
import { copyObject, sortByCreatedDateAsc, sortByCreatedDateDesc } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import { observable } from 'aurelia-typed-observable-plugin';
import type { Subscription } from '../../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Filter } from '../../Filters/Filter';
import { FiltersDialog } from '../../Filters/FiltersDialog';
import type { IQuotesFilterItemDictionary } from '../QuoteFilters';
import { filterList, filterMenu } from '../QuoteFilters';
import { QuoteService } from '../QuoteService';

export class QuoteListCustomElement {
    private quotes: Array<Quote> = [];
    protected filteredQuotes: Array<Quote> = [];
    protected currentFilter: Filter<IQuotesFilterItemDictionary> = filterMenu();
    private _dataChangedSub: Subscription;
    @observable protected searchText: string;
    public searchTextChanged() {
        this.filterAndSort();
    }

    @computedFrom('quotes')
    get quotesCount() {
        return this.quotes.length;
    }

    attached() {
        this.loadQuotes();
        this._dataChangedSub = DataRefreshedEvent.subscribe((e: DataRefreshedEvent) => {
            if (e.hasAnyType('quotes')) {
                this.updateQuotes(copyObject(e.filterByType<Quote>('quotes')));
                // this.loadQuotes();
            }
        });
    }

    // WTF:  Only update the quotes that have changed and use a clone to ensure properties are updated in the view
    updateQuotes(quotes: Array<Quote>) {
        for (const quote of quotes) {
            const index = this.quotes.findIndex(q => q._id === quote._id);
            if (index !== -1) {
                if (quote._deleted) {
                    this.quotes.splice(index, 1);
                } else {
                    this.quotes[index] = quote;
                }
            } else {
                this.quotes.push(quote);
            }
            const filteredIndex = this.filteredQuotes.findIndex(q => q._id === quote._id);
            if (filteredIndex !== -1) {
                if (quote._deleted) {
                    this.filteredQuotes.splice(filteredIndex, 1);
                } else {
                    this.filteredQuotes[filteredIndex] = quote;
                }
            } else {
                this.filteredQuotes.push(quote);
            }
        }

        this.filterAndSort();
    }
    detached() {
        if (this._dataChangedSub) this._dataChangedSub.dispose();
    }
    protected loadQuotes() {
        const quotes = copyObject(QuoteService.getQuotes());
        this.quotes = quotes;

        this.filteredQuotes = [...quotes];
        this.filterAndSort();
    }

    private filterAndSort() {
        this.filteredQuotes = filterList([...this.quotes], this.searchText || '', this.currentFilter);
        const sortOption = this.currentFilter?.filterOptions?.sortBy?.selectedOption;

        this.filteredQuotes = this.filteredQuotes.slice().sort(sortOption?.reverse ? sortByCreatedDateAsc : sortByCreatedDateDesc);
    }
    protected clearFilters() {
        this.currentFilter = filterMenu();
        this.filterAndSort();
    }
    protected async openFilterMenu() {
        const dialog = new FiltersDialog<IQuotesFilterItemDictionary>(this.currentFilter, filterMenu);

        const result = await dialog.show();
        if (!dialog.cancelled) {
            this.currentFilter = result;
            this.filterAndSort();
        }
    }
}
