import type { ListItemButtonProps } from '@mui/material';
import { Box, darken, lighten, List, ListItemButton, Paper, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import type { AureliaReactComponentDialogComponent } from '../../Dialogs/AureliaReactComponentDialogComponent';
import type { ScheduleItemMessageModel } from '../../Schedule/ScheduleItemMessageModel';
import { ScheduleService } from '../../Schedule/ScheduleService';
import useTranslation from '../hooks/useTranslation';
import { generateDays } from './functions/generateDays';
import { generateTimes } from './functions/generateTimes';
import { getDefaultQuickSchedule } from './functions/getDefaultQuickSchedule';
import { setDefaultQuickSchedule } from './functions/setDefaultQuickSchedule';
import type { QuickScheduleOptions } from './QuickScheduleOptions';
import { SimpleDateTimePickerComponent } from './SimpleDateTimePickerComponent';

type Options = {
    targetDate?: Date;
    direction?: 'forwards' | 'backwards';
    quickSchedule?: QuickScheduleOptions;
    isEmail?: boolean;
};

const defaultQuickSchedule: QuickScheduleOptions = {
    availableDayOptions: [{ value: 0 }, { value: 1 }, { value: 7 }, { value: 14 }, { value: 30 }],
    availableTimeOptions: [
        { label: 'dates.early-morning', hour: 7, minute: 0 },
        { label: 'dates.morning', hour: 9, minute: 0 },
        { label: 'dates.afternoon', hour: 13, minute: 0 },
        { label: 'dates.evening', hour: 18, minute: 0 },
        { label: 'dates.late-evening', hour: 20, minute: 0 },
    ],
};

const selectedSx: ListItemButtonProps['sx'] = {
    background: theme =>
        theme.palette.mode === 'dark' ? lighten(theme.palette.background.paper, 0.1) : darken(theme.palette.background.paper, 0.1),
    borderRadius: 1,
};

const unselectedSx: ListItemButtonProps['sx'] = {
    boxShadow: 'none',
    borderRadius: 1,
};

export type ScheduleMessageDialogOptions = {
    message?: string;
    options?: Options;
    model?: ScheduleItemMessageModel;
};

type Props = {
    okCallback: (date: number) => void;
    cancelCallback: () => void;
} & ScheduleMessageDialogOptions;

export const ScheduleMessageDialogComponent: AureliaReactComponentDialogComponent<ScheduleMessageDialogOptions> = ({
    okCallback,
    cancelCallback,
    message,
    options,
    model,
}: Props) => {
    const { t } = useTranslation();
    if (!options) options = {} as Options;
    const { targetDate = new Date(), quickSchedule = defaultQuickSchedule } = options;

    if (!quickSchedule.availableDayOptions) quickSchedule.availableDayOptions = defaultQuickSchedule.availableDayOptions || [];
    if (!quickSchedule.availableTimeOptions) quickSchedule.availableTimeOptions = defaultQuickSchedule.availableTimeOptions || [];

    const { defaultDays, defaultHour, defaultMinute } = getDefaultQuickSchedule(options?.direction || 'forwards') || {
        defaultDays: quickSchedule.availableDayOptions[0]?.value || 0,
        defaultHour: quickSchedule.availableTimeOptions[0]?.hour ?? new Date().getHours(),
        defaultMinute: quickSchedule.availableTimeOptions[0]?.minute ?? new Date().getMinutes(),
    };

    const defaultDate = moment(targetDate).add(defaultDays, 'days').hour(defaultHour).minute(defaultMinute).toDate();

    const [selectedDate, setSelectedDate] = useState<Date>(defaultDate);

    const days = useMemo(() => generateDays(targetDate, quickSchedule.availableDayOptions), [targetDate]);

    const times = useMemo(() => generateTimes(selectedDate, quickSchedule.availableTimeOptions), [selectedDate]);

    const handleQuickDay = (date: string) => {
        const value = days.find(d => d.date === date)?.value;
        if (value === undefined) return;

        let newSelectedDate = moment(targetDate).startOf('day').add(value, 'days');

        // If the selected date is before today set it to today.
        if (newSelectedDate.isBefore(moment(), 'day')) newSelectedDate = moment().startOf('day');

        const hour = selectedDate.getHours();
        const minute = selectedDate.getMinutes();

        newSelectedDate.hour(hour).minute(minute);

        // If the selected date is today the future, then we can just set the time to the currently selected time.
        if (newSelectedDate.isBefore(moment())) {
            newSelectedDate = moment();
            const updatedTimes = generateTimes(newSelectedDate.toDate(), quickSchedule.availableTimeOptions);
            const nextAvailableTime = updatedTimes.find(({ disabled }) => !disabled);
            if (nextAvailableTime) newSelectedDate.hour(nextAvailableTime.hour).minute(nextAvailableTime.minute);
        }

        setSelectedDate(newSelectedDate.toDate());

        updateQuickOptionsFromSelectedDate(newSelectedDate);
    };

    const updateQuickOptionsFromSelectedDate = (selectedDate: moment.Moment) => {
        const hour = selectedDate.hour();
        const minute = selectedDate.minute();

        const date = selectedDate.format('YYYY-MM-DD');
        setSelectedQuickDay(date);

        const quickTime = `${hour}:${minute}`;
        setSelectedQuickTime(quickTime);

        if (date && quickTime) {
            const day = days.find(d => d.date === date)?.value;
            const time = times.find(t => t.id === quickTime);
            if (day !== undefined && time !== undefined)
                setDefaultQuickSchedule(day, time.hour, time.minute, options?.direction || 'forwards');
        }
    };

    const handleQuickTime = (hour: number, minute: number) => {
        const newSelectedDate = moment(selectedDate).startOf('day').add(hour, 'hour').add(minute, 'minute');
        setSelectedDate(newSelectedDate.toDate());
        updateQuickOptionsFromSelectedDate(newSelectedDate);
    };

    useEffect(() => handleQuickDay(moment(selectedDate).format('YYYY-MM-DD')), []);

    const [selectedQuickDay, setSelectedQuickDay] = useState<string>();
    const [selectedQuickTime, setSelectedQuickTime] = useState<string>();

    const previewMessage = model ? ScheduleService.scheduledMessageUpdateTokens(message || '', selectedDate.valueOf() / 1000, model) : '';

    return (
        <>
            {/* {options.isEmail && (
                <Alert severity="info" variant="filled" sx={{ borderRadius: 0 }}>
                    {t('schedule-message.sendgrid-72-hour-limit-message')}
                </Alert>
            )} */}
            <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', p: 2 }}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6">Quick Schedule</Typography>
                    <Typography variant="h6">{moment(targetDate).format('ddd ll')}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <List sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        {days.map(({ label, date: value, disabled }) => (
                            <ListItemButton
                                key={label}
                                onClick={() => handleQuickDay(value)}
                                sx={{
                                    ...(value === selectedQuickDay ? selectedSx : unselectedSx),
                                    '&:hover': {
                                        ...(value === selectedQuickDay ? selectedSx : unselectedSx),
                                    },
                                }}
                                disabled={disabled}
                            >
                                {label}
                            </ListItemButton>
                        ))}
                    </List>
                    <List sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        {times.map(({ label, id, hour, minute, disabled }) => (
                            <ListItemButton
                                key={label}
                                onClick={() => handleQuickTime(hour, minute)}
                                sx={{
                                    ...(id === selectedQuickTime ? selectedSx : unselectedSx),
                                    '&:hover': {
                                        ...(id === selectedQuickTime ? selectedSx : unselectedSx),
                                    },
                                }}
                                disabled={disabled}
                            >
                                {t(label)}
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
                <Typography variant="h6">Custom Schedule</Typography>
                <SimpleDateTimePickerComponent
                    preselectedDate={selectedDate.valueOf()}
                    dateSelected={(date: number) => {
                        setSelectedDate(moment(date).toDate());
                    }}
                    okCallback={okCallback}
                    cancelCallback={cancelCallback}
                    direction={options.direction}
                    targetDate={targetDate}
                    // sendGrid72HourLimit={options.isEmail ? moment().add('72', 'hours') : undefined}
                />

                {!previewMessage ? null : (
                    <Typography variant="h6" marginTop={2}>
                        Preview
                    </Typography>
                )}
                <Typography variant="subtitle1" marginTop={2} mb={2}>
                    This message will be sent at {moment(selectedDate).format('h:mma')}
                    {moment(selectedDate).startOf('day').diff(moment(targetDate).startOf('day'), 'days') === 0
                        ? ' today'
                        : moment(selectedDate).format(' on ddd ll')}
                    {previewMessage ? ':' : '.'}
                </Typography>
                {previewMessage ? (
                    <Box component={Paper} p={2}>
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                            {previewMessage ? previewMessage : null}
                        </Typography>
                    </Box>
                ) : null}
            </Box>
        </>
    );
};
